import React from 'react';

const Investor = () => (
  <article className="wrapper bg-green-bright py-5">
    <div className="container-fluid py-4">
      <h1 className="font-weight-bolder text-black white-underscore">We welcome<br />investors</h1>
      <div className="row">
        <div className="col-12 col-md-5 d-flex align-items-center justify-content-start text-left">
          <div>
            <h4 className="font-weight-lighter text-white">You can only find out how far you can go by going further than ever before.</h4>
            <h4 className="font-weight-lighter text-white">We are setting the bar for the next stage of commercial technology in business.</h4>
          </div>
        </div>
        <div className="col-12 col-md-7 d-flex flex-wrap flex-lg-nowrap">
          <div className="mr-3 mt-1 mb-4 d-none d-md-block" style={{ borderLeft: 5, borderLeftStyle: 'solid', borderLeftColor: '#35caf9' }} />

          <div>
            <div className="display-4 text-white font-weight-bolder">Join us to be part of the future of sustainable technology.</div>
            <div className="display-4 text-black font-weight-bold mt-2">Investor Relations</div>
            <div className="display-4">
              <a
                href="mailto:invest@emvelia.com"
                target="_blank"
                className="text-white"
              >
                invest@emvelia.com
              </a>
            </div>
          </div>
          <img src="/assets/eis.png" className="img-thumbnail d-block mt-4 mt-md-0 ml-0 ml-md-4" alt="SEIS" style={{ maxHeight: 165 }} />
        </div>
      </div>
    </div>
  </article>
);

export default Investor;
