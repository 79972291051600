import React from 'react';
import InvestorsBanner from 'components/investors';
import TogetherBanner from 'components/togetherBanner';
import SEO from 'components/seo';


const Investors = () => (
  <>
    <SEO title="Investors" />
    <div className="container-fluid mt-6 py-6" style={{ backgroundImage: 'url(/assets/bg-investors.png)', backgroundPosition: 'center' }}>
      <div className="row justify-content-center text-center">
        <div className="col-12 col-md-7">
          <i className="ai-logo-artlimes" style={{ fontSize: 200 }} />
          <div className="display-2 border-bottom-dashed font-weight-bolder pb-2 mb-2">
            Welcome to Emvelia Technologies
            <div className="text-secondary-light display-6 font-weight-light">A true Innovation Technology Company.</div>

          </div>
          <div className="row mt-6 pt-5 pl-md-3">
            <div className="col-12 col-lg-6 border d-flex align-items-center text-left justify-content-center rounded-lg bg-white order-lg-1 order-2 mt-5 mt-lg-0 p-7">
              <div>
                <div
                  style={{
                    borderBottom: 3,
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#35caf9',
                    paddingBottom: 6,
                    marginBottom: 6,
                  }}
                  className="d-block display-5 font-weight-bolder"
                >
                  Investor Relations
                </div>
                <a
                  href="mailto:invest@emvelia.com"
                  target="_blank"
                  className="text-dark"
                >
                  invest@emvelia.com
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end mt-6 mt-lg-0 order-lg-2 order-1">
              <form>
                <div className="form-group">
                  <div className="text-lg-left">
                    <label className="display-5 font-weight-bolder">Use your password to connect</label>
                  </div>
                  <div className="text-lg-left">
                    <input
                      type="password"
                      className="form-control bg-primary text-white"
                      required
                      style={{ maxWidth: 300 }}
                    />
                  </div>
                </div>
                <div className="text-lg-left">
                  <button type="submit" className="btn btn-light">Connect</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TogetherBanner />
    <InvestorsBanner />
  </>
);

export default Investors;
